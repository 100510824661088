import { ComponentStore } from '@ngrx/component-store';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ContentItem } from '../../../../share/model/atomic-model';
import { Helper } from '../../../../share/helper';

export interface ChapterTopicsPageState {
    selectedChapter: Content;
}

const DEFAULT_STATE: ChapterTopicsPageState = {
    selectedChapter: {
        eId: '',
        type: OIV_TYPE.CHAPTER,
        content: [],
    },
};

@Injectable()
export class ChapterTopicsPageComponentStore extends ComponentStore<ChapterTopicsPageState> {
    private readonly store = inject(Store);
    private readonly helper = inject(Helper);

    constructor() {
        // initial state
        super(DEFAULT_STATE);
    }

    readonly setSelectedChapter = this.updater((state, selectedChapter: Content) => {
        return {
            ...state,
            selectedChapter,
        };
    });

    readonly getSelectedChapter$ = this.select(state => {
        return state.selectedChapter;
    });

    readonly getChapters = this.select(this.getSelectedChapter$, selectedChapter => {
        return [this.helper.buildChapter(selectedChapter)];
    });

    readonly mapTopicsToNavigationItems = this.select(this.getChapters, selectedChapter => {
        return this.helper.buildNavigationItems(selectedChapter[0].sections, true);
    });

    readonly getHeading = this.select(this.getSelectedChapter$, selectedChapter => {
        const chapterTitle =
            (selectedChapter.content as Array<Content>).find(item => item.type === OIV_TYPE.CHAPTER_TITLE) ||
            ({
                type: OIV_TYPE.CHAPTER_TITLE,
            } as Content);
        const heading: ContentItem = {
            eId: chapterTitle.eId,
            type: chapterTitle.type,
            content: chapterTitle.content as string,
        };
        return heading;
    });
}
